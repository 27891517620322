import config from '../../../config';

const requestInterceptor = (requestConfig) => {
  const transformedConfig = requestConfig;

  transformedConfig.params = {
    live: config.isLive,
    ...requestConfig.params,
  };

  if (config.isMobile.device) transformedConfig.params.mobile = true;
  else transformedConfig.params.desktop = true;

  transformedConfig.headers['X-Nsft-SCD-Version'] = '4';
  transformedConfig.headers['X-Nsft-WebAPI-Locale'] = config.language.iso1;
  transformedConfig.headers['X-Nsft-WebAPI-Company'] = config.tenant.name;
  transformedConfig.headers['X-Nsft-WebAPI-CompanyUuid'] = config.tenant.uuid;

  return transformedConfig;
};

export default { requestInterceptor };
