import logger from '@/utility/logger';
import config from '../../config';

const isMobile = config.isMobile.device;

class Game {
  constructor(game, isGameFavorite) {
    this.id = game.id;
    this.name = game.name;
    this.disabled = game.disabled;
    this.providerId = game.provider?.id ? game.provider.id : game.providerId;
    this.type = game.type;
    this.title = game.title;
    this.description = game.description;
    this.demo = game.demo;
    this.new = game.new;
    this.fullscreen = game.fullscreen;
    this.allowMaximize = game.fullscreen;
    this.width = game.width;
    this.height = game.height;
    this.linkUuid = game.linkUuid;
    this.productDisplayId = game.productDisplayId;
    this.aggregateTitle = game.aggregateTitle;
    this.code = game.code;
    this.groups = game.groups;
    this.subgroups = game.subgroups;
    this.favorite = isGameFavorite;
    this.url = '';
    this.resolving = false;
    this.provider = {};
    this.position = game.position;
    this.sticker = game.image?.sticker;
    this.swimLane = game.swimLane || '';

    if (!game.image.thumb) {
      this.image = game.image;
    } else {
      this.image = isMobile ? game.image.thumbMobile : game.image.thumb;
    }
  }

  getUrl({ demo, user, tenant, referrerUrl, language }) {
    try {
      return this.provider.getUrl({
        game: this,
        demo,
        user,
        tenant,
        referrerUrl,
        language,
      });
    } catch (error) {
      logger.error(`Opening game failed ${error}`);
      this.setResolving(false);
      return null;
    }
  }

  setUrl(url) {
    this.url = url;
  }

  setProvider(providers) {
    this.provider = providers.find((provider) => provider.id === this.providerId);
  }

  setOrder(order) {
    this.order = order;
  }

  setResolving(isResolving) {
    this.resolving = isResolving;
  }

  setFavorite(isFavorite) {
    this.favorite = isFavorite;
  }
}

export default Game;
