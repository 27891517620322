export default {
  hasBootstrapFailed: (state) => state.hasBootstrapFailed,
  translations: (state) => state.translations,
  layout: (state) => state.applicationSettings.layout,
  theme: (state) => state.applicationSettings.theme,
  palette: (state) => state.applicationSettings.palette,

  getLanguage: (state) => state.language,
  getMarketingSlot: (state) => (name) => state.marketingSlots.find((slot) => slot.name === name),
  getTenant: (state) => state.tenant,
  getReferrerUrl: (state) => state.referrerUrl,
  getNotification: (state) => state.notification,
  getVisualViewport: (state) => state.visualViewport,

  isIOS12: (state) => state.iOSversion.iOS12,
  isLive: (state) => state.isLive,
  isMobileDevice: (state) => state.isMobile.device,
  isVaixActive: (state) => state.applicationSettings.isVaixActive,
  isDescriptionBoxActive: (state) => state.applicationSettings.isDescriptionBoxActive,
  isScrollable: (state) => state.isScrollable,
};
