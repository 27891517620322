export default {
  SET_PROVIDERS: 'SET_PROVIDERS',
  SET_GAMES: 'SET_GAMES',
  SET_FAVORITE_GAMES: 'SET_FAVORITE_GAMES',
  SET_GROUPS: 'SET_GROUPS',
  SET_POPULAR_GAMES: 'SET_POPULAR_GAMES',
  SET_PROMOTED_GAMES: 'SET_PROMOTED_GAMES',
  SET_RECOMMENDED_GAMES: 'SET_RECOMMENDED_GAMES',
  SET_BECAUSE_YOU_PLAYED_GAMES: 'SET_BECAUSE_YOU_PLAYED_GAMES',

  SET_PAGINATION: 'SET_PAGINATION',
  SET_PAGINATION_FIRST_PAGE: 'SET_PAGINATION_FIRST_PAGE',
  SET_PAGINATION_NEXT_PAGE: 'SET_PAGINATION_NEXT_PAGE',
  UPDATE_GAMES_FAVORITE_PROPERTY: 'UPDATE_GAMES_FAVORITE_PROPERTY',
  UPDATE_DEFAULT_GROUPS: 'UPDATE_DEFAULT_GROUPS',
  REMOVE_POPULAR_GROUP: 'REMOVE_POPULAR_GROUP',
  ADD_FAVORITE_GAME: 'ADD_FAVORITE_GAME',
  REMOVE_FAVORITE_GAME: 'REMOVE_FAVORITE_GAME',
  SET_FILTER_PARAMETER: 'SET_FILTER_PARAMETER',
  RESET_SUBGROUP: 'RESET_SUBGROUP',

  REMOVE_ACTIVE_GAME_URL: 'REMOVE_ACTIVE_GAME_URL',
  SET_ACTIVE_GAME: 'SET_ACTIVE_GAME',
  SET_LAST_PLAYED_GAME: 'SET_LAST_PLAYED_GAME',
  SET_DEMO_MODE: 'SET_DEMO_MODE',
  SET_TOURNAMENT_GAMES: 'SET_TOURNAMENT_GAMES',
  SET_TOP_10_GAMES: 'SET_TOP_10_GAMES',
  SET_TRENDING_THIS_WEEK_GAMES: 'SET_TRENDING_THIS_WEEK_GAMES',
  SET_NEW_RELEASES_GAMES: 'SET_NEW_RELEASES_GAMES',
  SET_RECENTLY_PLAYED_GAMES: 'SET_RECENTLY_PLAYED_GAMES',
};
