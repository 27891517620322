import integrator from '@/services/integrator';
import { filtersParser } from '@/utility';
import constants from '@/utility/constants';
import config from '../../config';

const { replaceSpacesWithDashes, getIdFromParam, getNameFromParam } = filtersParser;

const getGroupParams = ({ groupId, groupName, subgroupId, subgroupName }) => {
  let param1;
  let param2;

  // default group 'all' so no need to send params
  if (groupId !== constants.GROUP_IDS.ALL) {
    param1 = `${groupId}-${replaceSpacesWithDashes(groupName)}`;
  }

  if (subgroupName) param2 = `${subgroupId}-${replaceSpacesWithDashes(subgroupName)}`;

  return {
    param1,
    param2,
  };
};

const getGameParams = (gameId, gameName) => {
  // Set explicit 'game' path for 3rd party integrations
  const param1 = constants.INTEGRATOR_PARAMS.GAME;
  const param2 = `${gameId}-${replaceSpacesWithDashes(gameName)}`;

  return {
    param1,
    param2,
  };
};

export default {
  /**
   * Handles query params coming from 3rd party that should be applied to lobby route path
   * parses incoming query params into normalized version
   */
  handleInitialParams: (to, from, next) => {
    const initialParams = config.initialRouteParams;

    const { param1, param2 } = initialParams;

    if (!param1) {
      next();
      return;
    }

    const finalParams = to.params;

    if (param1 === 'game') {
      finalParams.gameId = getIdFromParam(param2);
    } else {
      finalParams.groupName = getNameFromParam(param1);
      finalParams.groupId = getIdFromParam(param1);

      finalParams.subgroupName = getNameFromParam(param2);
      finalParams.subgroupId = getIdFromParam(param2);
    }

    // reset initial params so app doesn't get stuck in an infinite redirect loop
    config.initialRouteParams = {};

    // explicitly spread route params, otherwise changes won't be applied
    next({ name: to.name, params: { ...finalParams } });
  },

  /**
   * Notifies parent frame that route has changed
   * parses outgoing query params into dashed version
   */
  notifyRouteChange: (to) => {
    const { groupName, subgroupName, game, gameId, groupId, subgroupId } = to.params;

    const isGameQuery = game && gameId;
    const isGroupsQuery = !!groupId;
    const isInvalidQuery = !isGroupsQuery && !isGameQuery;

    if (isInvalidQuery) {
      return;
    }

    let params;
    let titleBreadcrumb;

    if (isGroupsQuery) {
      params = getGroupParams({
        groupId,
        groupName,
        subgroupId,
        subgroupName,
      });

      titleBreadcrumb = subgroupName ? [subgroupName, groupName] : [groupName];
    } else if (isGameQuery) {
      params = getGameParams(gameId, game);

      titleBreadcrumb = [game];
    }

    integrator.notifyRouteChange({
      path: to.fullPath,
      params,
    });

    integrator.notifyChangeTitle(titleBreadcrumb);
  },
};
