import types from './types';
import config from '../../config';

export default {
  [types.SET_APPLICATION_SETTINGS](
    state,
    {
      theme,
      palette,
      layout,
      font,
      GTMCode,
      isDescriptionBoxActive,
      isVaixActive,
      liveCasinoGroupsActive,
    },
  ) {
    state.applicationSettings.theme = theme;
    state.applicationSettings.palette = palette;
    state.applicationSettings.layout = layout;
    state.applicationSettings.font = font;
    state.applicationSettings.GTMCode = GTMCode;
    state.applicationSettings.isVaixActive = !config.isLive && isVaixActive;
    state.applicationSettings.isDescriptionBoxActive = isDescriptionBoxActive;
    state.applicationSettings.liveCasinoGroupsActive = liveCasinoGroupsActive;
  },
  [types.SET_BOOTSTRAP_FAILED](state) {
    state.hasBootstrapFailed = true;
  },
  [types.SET_CONFIG](state, payload) {
    state.iOSversion.iOS12 = payload.isIOS12;
    state.isMobile.device = payload.isMobile.device;
    state.isLive = payload.isLive;
  },
  [types.SET_LANGUAGE](state, payload) {
    state.language = payload;
  },
  [types.SET_TENANT](state, payload) {
    const { currency, tenant } = payload;

    state.tenant = {
      name: tenant.name,
      uuid: tenant.uuid,
      currency: currency.symbol,
      virtualCurrency: currency.virtualSymbol,
    };
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = payload;
  },
  [types.SET_VISUAL_VIEWPORT_HEIGHT](state, payload) {
    state.visualViewport.height = payload.height;
  },
  [types.SET_VISUAL_VIEWPORT_SCROLL](state, payload) {
    state.visualViewport.scrollTop = payload.scrollTop;
  },
  [types.SET_SCROLLABILITY](state, payload) {
    state.isScrollable = payload;
  },
  [types.SET_REFERRER_URL](state, payload) {
    state.referrerUrl = payload;
  },

  [types.SET_MARKETING_SLOTS](state, payload) {
    state.marketingSlots = payload;
  },

  [types.SHOW_NOTIFICATION](state, { text, variant = 'negative' }) {
    const NOTIFICATION_DELAY = 5000;

    if (state.notification.isVisible) clearTimeout(state.notification.timeout);

    state.notification.variant = variant;
    state.notification.text = text;
    state.notification.isVisible = true;

    state.notification.timeout = setTimeout(() => {
      state.notification.isVisible = false;
    }, NOTIFICATION_DELAY);
  },
  [types.HIDE_NOTIFICATION](state) {
    state.notification.isVisible = false;
    clearTimeout(state.notification.timeout);
  },
};
