import { each } from 'lodash-es';
import constants from '@/utility/constants';
import types from './types';

export default {
  [types.SET_GAMES](state, payload) {
    state.games = payload;
  },
  [types.SET_POPULAR_GAMES](state, payload) {
    state.popularGames = payload;
  },
  [types.SET_PROMOTED_GAMES](state, payload) {
    state.promotedGames = payload;
  },
  [types.SET_FAVORITE_GAMES](state, payload) {
    state.favoriteGames = payload;
  },
  [types.SET_RECENTLY_PLAYED_GAMES](state, payload) {
    state.recentlyPlayedGames = payload;
  },
  [types.SET_RECOMMENDED_GAMES](state, payload) {
    state.recommendedGames = payload;
  },
  [types.SET_TOURNAMENT_GAMES](state, payload) {
    state.tournamentGames = payload;
  },
  [types.SET_TOP_10_GAMES](state, payload) {
    state.top10Games = payload;
  },
  [types.SET_TRENDING_THIS_WEEK_GAMES](state, payload) {
    state.trendingThisWeekGames = payload;
  },
  [types.SET_NEW_RELEASES_GAMES](state, payload) {
    state.newReleasesGames = payload;
  },
  [types.SET_BECAUSE_YOU_PLAYED_GAMES](state, payload) {
    state.becauseYouPlayedGames = payload;
  },
  [types.SET_PROVIDERS](state, payload) {
    state.providers = payload;
  },
  [types.SET_GROUPS](state, payload) {
    state.groups = payload;
  },
  [types.SET_ACTIVE_GAME](state, payload) {
    state.activeGame = payload;
  },
  [types.SET_LAST_PLAYED_GAME](state, payload) {
    state.lastPlayedGame = payload;
  },
  [types.REMOVE_ACTIVE_GAME_URL](state) {
    state.activeGame.url = '';
  },
  [types.SET_DEMO_MODE](state, payload) {
    state.activeGame.isDemoMode = payload;
  },
  [types.REMOVE_POPULAR_GROUP](state) {
    state.groups = state.groups.filter((group) => group.id !== constants.GROUP_IDS.POPULAR);
  },
  [types.ADD_FAVORITE_GAME](state, game) {
    state.favoriteGames.push(game);
  },
  [types.REMOVE_FAVORITE_GAME](state, index) {
    state.favoriteGames.splice(index, 1);
  },
  [types.RESET_SUBGROUP](state) {
    state.filters.subgroup = null;
  },
  [types.SET_FILTER_PARAMETER](state, payload) {
    if ((!payload || !payload.value.id) && payload.filter !== 'search') return;

    const { filter, value } = payload;
    state.filters[filter] = value;
  },
  [types.SET_PAGINATION](state, payload) {
    state.pagination = { ...payload };
  },
  [types.SET_PAGINATION_FIRST_PAGE](state) {
    state.pagination.page = 1;
  },
  [types.SET_PAGINATION_NEXT_PAGE](state) {
    state.pagination.page += 1;
  },
  [types.UPDATE_GAMES_FAVORITE_PROPERTY](state, favoriteGamesIds) {
    state.games.forEach((game, index) => {
      state.games[index].favorite = favoriteGamesIds.includes(game.id);
    });

    state.promotedGames.forEach((game, index) => {
      state.promotedGames[index].favorite = favoriteGamesIds.includes(game.id);
    });

    state.popularGames.forEach((game, index) => {
      state.popularGames[index].favorite = favoriteGamesIds.includes(game.id);
    });

    state.recommendedGames.forEach((game, index) => {
      state.recommendedGames[index].favorite = favoriteGamesIds.includes(game.id);
    });

    state.tournamentGames.forEach((game, index) => {
      state.tournamentGames[index].favorite = favoriteGamesIds.includes(game.id);
    });

    state.top10Games.forEach((game, index) => {
      state.top10Games[index].favorite = favoriteGamesIds.includes(game.id);
    });

    state.trendingThisWeekGames.forEach((game, index) => {
      state.trendingThisWeekGames[index].favorite = favoriteGamesIds.includes(game.id);
    });

    if (state.activeGame)
      state.activeGame.favorite = favoriteGamesIds.includes(state.activeGame.id);
  },
  [types.UPDATE_DEFAULT_GROUPS](state, translations) {
    each(state.groups, (group, i) => {
      if (group.id > 0) return;

      state.groups[i].name = translations[group.titleKey];
    });
  },
};
