import { capitalize } from 'lodash-es';
import { eventBus, logger, prettyPrintJson } from '@/utility';
import constants from '@/utility/constants';

let instance = null;
let initializationResolve = null;

export default {
  init() {
    this.setInstance();
    this.setListener();

    return new Promise((resolve) => {
      initializationResolve = resolve;

      // Notify basic setup is done
      logger.info('Sending Slave.Init');
      instance.sendMessage(
        JSON.stringify({
          action: 'Slave.Init',
        }),
      );
    });
  },

  setInstance() {
    instance = {};

    instance.sendMessage = (message) => {
      try {
        window.WebAppListener.sendMessage(message);
      } catch (err) {
        logger.error(`Error sending message ${message} to android webview`, err);
      }
    };
  },

  notifyAppLoad() {
    logger.info('Sending Slave.Loaded');
    instance.sendMessage(
      JSON.stringify({
        action: 'Slave.Loaded',
      }),
    );
  },

  notifyGameEvent(event, game) {
    logger.info(`Notify game event: ${event}, ${game.url}`);
    instance.sendMessage(
      JSON.stringify({
        action: `Game.${capitalize(event)}`,
        data: {
          game: {
            id: game.id,
            productDisplayId: game.productDisplayId,
            code: game.code,
            demo: game.isDemoMode,
            title: game.title,
            providerName: game.provider.name,
            url: game.url,
            position: game.position,
            isVaixEvent: game.isVaixEvent,
            swimLane: game.swimLane,
            page: constants.VAIX.HOMEPAGE,
            isLive: game.isLive,
          },
        },
      }),
    );
  },

  notifyGameImpression({ productName, gameIds, vaixSwimLane }) {
    logger.info(`Notify Analytics game impression event: ${productName}`);
    instance.sendMessage(
      JSON.stringify({
        action: 'Analytics.Send',
        data: {
          type: 'VaixImpressions',
          params: {
            category: productName,
          },
          content: [
            {
              id: vaixSwimLane,
              value: {
                gameIds,
                page: constants.VAIX.HOMEPAGE,
              },
            },
          ],
        },
      }),
    );
  },

  notifyRouteChange() {},

  notifyChangeTitle() {},

  requireUserLogin() {
    logger.info('Sending User.LoginRequired');
    instance.sendMessage(
      JSON.stringify({
        action: 'User.LoginRequired',
      }),
    );
  },

  requestGameOpening() {},

  requestNavigationChange(url) {
    logger.info(`Sending Navigation.GoTo: ${url}`);
    instance.sendMessage(
      JSON.stringify({
        action: 'Navigation.GoTo',
        data: {
          url,
        },
      }),
    );
  },

  setListener() {
    window.onParentMessage = (message, payload) => {
      const initialData = payload.data;
      const isLoadMessage = message === 'Slave.Load';
      logger.info(
        `Incoming message from native integrator: ${message}, ${prettyPrintJson(payload)}`,
      );

      // Handle missing user.logged flag
      if (isLoadMessage || message === 'User.AuthorizationChanged') {
        initialData.user = {
          ...initialData.user,
          logged: !!initialData.user?.uuid,
          profile: {
            token: initialData.user?.profileToken,
          },
        };
      }

      if (isLoadMessage) {
        initialData.token = initialData.user?.auth?.token;
        initializationResolve(initialData);
      } else {
        eventBus.publish(message, initialData);
      }
    };
  },
};
