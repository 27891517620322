import { logger } from '@/utility';
import http from './http';

export default {
  async loadCarousels(playerUuid) {
    const url = '/casino/carousels';

    const headers = {
      'X-Nsft-WebAPI-playerUuid': playerUuid,
    };

    try {
      const { data } = await http.get(url, { headers });
      return { data };
    } catch (e) {
      logger.error(`Loading carousels failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },

  async loadLastPlayedGame(userToken) {
    const url = `${process.env.VUE_APP_INTEGRATIONS_API}/games/last`;
    const headers = { Authorization: `Bearer ${userToken}` };

    try {
      const {
        data: { game },
      } = await http.get(url, { headers });
      return { game };
    } catch (e) {
      logger.error(`Loading last played game failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },

  async loadLastGameByProductDisplayId(userToken, productDisplayId) {
    const url = '/casino/web/games';
    const params = { productDisplayId };
    const headers = { Authorization: `Bearer ${userToken}` };

    try {
      const {
        data: {
          games: [game],
        },
      } = await http.get(url, { headers, params });
      return { game };
    } catch (e) {
      logger.error(`Loading last game by product display id failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },

  async loadBecauseYouPlayedGames(playerUuid, userToken, productDisplayId) {
    const url = '/casino/carousel';
    const params = {
      type: 'because_you_played',
      productDisplayId,
    };
    const headers = {
      'X-Nsft-WebAPI-playerUuid': playerUuid,
      Authorization: `Bearer ${userToken}`,
    };

    try {
      const {
        data: { games },
      } = await http.get(url, { headers, params });
      return { games };
    } catch (e) {
      logger.error(`Loading 'Because you played' games failed: ${e.message}`);

      return {
        isError: true,
      };
    }
  },
};
