import { template, templateSettings, merge, forEach } from 'lodash-es';
import axios from 'axios';
import logger from '@/utility/logger';
import { qpSerializer } from '@/utility';
import gameUrlCloudflareInterceptor from '@/api/interceptors/gameUrlCloudflare';
import config from '../../config';

// Use custom template delimiters.
templateSettings.interpolate = /{([\s\S]+?)}/g;

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 15000,
  paramsSerializer: qpSerializer.encode,
});

if (config.isCloudflareActive) {
  http.interceptors.request.use(gameUrlCloudflareInterceptor, (error) => Promise.reject(error));
}

class Provider {
  constructor(provider) {
    this.id = provider.id;
    this.name = provider.name;
    this.customData = provider.customData;
    this.iframe = provider.iframe;
    this.playMode = {
      fun: 'FUN',
      real: 'REAL',
    };
    this.gameMode = {
      fun: 'fun',
      real: 'money',
    };
    this.additionalPathParams = Provider.getAdditionalPath(this.name);
  }

  getUrl(params) {
    return this.iframe.resolve ? this.resolveUrl(params) : this.createStandardUrl(params);
  }

  /**
   * Actual game URL needs to be fetched from endpoint - resolve strategy
   * @param {*} params - object, props: game, demo, user, tenant, referrerUrl, language
   * @returns Promise
   */
  async resolveUrl(params) {
    const compiled = template(this.iframe.path + this.additionalPathParams);
    const commonParams = this.getCommonParams(params);
    const additionalParams = {
      demo: params.demo ? 1 : 0,
      languageCode: params.language.iso2,
      language: params.language.iso1,
      homeUrl: params.referrerUrl || window.location.href,
    };
    const postResolveQueryParams = params.game.provider.iframe?.customData?.postResolveQueryParams;

    const gameUrl = this.iframe.base + compiled(merge(commonParams, additionalParams));

    try {
      const result = await http.get(gameUrl, {
        headers: {
          Authorization: `Bearer ${params.user.token}`,
        },
      });

      if (!config.isNativeApp || !postResolveQueryParams) return result.data.url;

      return Provider.createPostResolveUrl(result.data.url, postResolveQueryParams, commonParams);
    } catch (error) {
      logger.error(error);
      return null;
    }
  }

  /**
   * Game URL for direct opening - direct url strategy
   * @param {*} params - object { game, demo, user, tenant, referrerUrl, language }
   * @returns URL to open
   */
  createStandardUrl(params) {
    const { user } = params;
    const compiled = template(this.iframe.path);

    const commonParams = this.getCommonParams(params);
    const additionalParams = {
      token: window.encodeURIComponent(user.profileToken),
      username: user.name,
      languageCode: params.language.iso2.toUpperCase(),
    };

    return this.iframe.base + compiled(merge(commonParams, additionalParams));
  }

  /**
   * Creates object with common parameters for all URL strategies
   * @param {*} param - object with props:
   * game - object
   * demo - boolean
   * user - object
   * tenant - object
   * referrerUrl - string
   * language - object
   * @returns object with common parameters
   */
  getCommonParams({ game, demo, user, tenant, referrerUrl, language }) {
    return {
      game: game.code,
      productDisplayId: game.productDisplayId,
      linkId: game.linkUuid,
      company:
        this.customData && this.customData.companyAlias
          ? this.customData.companyAlias
          : tenant.name,
      tenantUuid: tenant.uuid,
      currency: tenant.currency,
      virtualCurrency: tenant.virtualCurrency,
      language: language.iso1,
      languageShort: language.iso1.substring(0, 2),
      timezoneOffset: user.timezoneOffset,
      oddsFormat: user.oddsFormat,
      isMobile: config.isMobile.device,
      sevenToken: window.encodeURIComponent(user.token),
      playerId: demo ? '' : user.id,
      playMode: demo ? this.playMode.fun : this.playMode.real,
      gameMode: demo ? this.gameMode.fun : this.gameMode.real,
      isDemo: demo.toString(),
      referrerUrl: referrerUrl || window.location.href,
      sevenDeliveryPlatform: config.isNativeApp ? 'mobile' : 'web',
    };
  }

  /**
   * Creates new url by adding new query params to resolved url
   * @param {*} url - string, resolved url
   * @param {*} params - object with new query params
   * @param {*} commonParams - object, common parameters to use in new query params
   * @returns string, new url
   */
  static createPostResolveUrl(url, params, commonParams) {
    let newParams = '';
    const newUrl = new URL(url);

    forEach(params, (value, key) => {
      if (newUrl.searchParams.has(key)) {
        newUrl.searchParams.delete(key);
      }

      if (newUrl.searchParams.has(key.toLowerCase())) {
        newUrl.searchParams.delete(key.toLowerCase());
      }

      newParams += newUrl.href.includes('?') || newParams.includes('?') ? '&' : '?';
      newParams += `${key}=${value}`;
    });

    const compiled = template(newParams);

    return newUrl.href + compiled(commonParams);
  }

  static getAdditionalPath(providerName) {
    if (providerName === 'blueocean') {
      return '&homeUrl={homeUrl}';
    }

    return '';
  }
}

export default Provider;
