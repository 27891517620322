import { logger } from '@/utility';
import GGSlave from './implementations/seven-gravity-gateway/slave';
import nativeWebview from './implementations/webview';
import nativeWKWebview from './implementations/wkwebview';

const integrator = {
  instance: null,
  init(config) {
    if (config.type === 'gravityGateway') {
      this.instance = GGSlave;
    } else if (config.type === 'webview') {
      this.instance = nativeWebview;
    } else if (config.type === 'wkwebview') {
      this.instance = nativeWKWebview;
    } else {
      logger.error(`Unknown integration implementation: ${config.type}`);
    }

    return this.instance?.init(config);
  },

  notifyAppLoad() {
    return this.instance?.notifyAppLoad();
  },

  /**
   * @param  {string} event
   * @param  {Game} game
   */
  notifyGameEvent(event, game) {
    return this.instance?.notifyGameEvent(event, game);
  },

  notifyGameImpression(event) {
    return this.instance?.notifyGameImpression(event);
  },

  notifyRouteChange(data) {
    return this.instance?.notifyRouteChange(data);
  },

  notifyChangeTitle(data) {
    return this.instance?.notifyChangeTitle(data);
  },

  requestGameOpening(data) {
    return this.instance?.requestGameOpening(data);
  },

  requestNavigationChange(url) {
    return this.instance?.requestNavigationChange(url);
  },

  requireUserLogin() {
    return this.instance?.requireUserLogin();
  },

  sendMessage(message) {
    return this.instance?.sendMessage(message);
  },

  subscribe(event, callback) {
    return this.instance?.subscribe(event, callback);
  },
};

export default integrator;
