const ENDPOINT_ONE = 'https://services.7platform.com';
const ENDPOINT_TWO = 'https://services-rs.7platform.com';
const ENDPOINT_THREE = 'https://services-rs2.7platform.com';

const ENDPOINT_ONE_CF = 'https://menhir.gb.nsoftcdn.com';
const ENDPOINT_TWO_CF = 'https://menhir.rs.nsoftcdn.com';
const ENDPOINT_THREE_CF = 'https://menhir.rs-2.nsoftcdn.com';

const endpoints = {
  [ENDPOINT_ONE]: ENDPOINT_ONE_CF,
  [ENDPOINT_TWO]: ENDPOINT_TWO_CF,
  [ENDPOINT_THREE]: ENDPOINT_THREE_CF,
};

const interceptor = (requestConfig) => {
  const transformedConfig = requestConfig;
  const { url } = transformedConfig;

  const urlData = new URL(url);
  const { origin } = urlData;

  // replace url base with cloudflare url base
  if (endpoints[origin]) {
    transformedConfig.url = url.replace(origin, endpoints[origin]);
  }

  return transformedConfig;
};

export default interceptor;
