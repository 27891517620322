import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const initializeErrorTracker = (app, router) => {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_APP_URL, /^\//],
      }),
    ],
    logErrors: true,
    ignoreErrors: [
      /Loading chunk .+ failed/i,
      /Loading CSS chunk .+ failed/i,
      /Cannot read properties of null \(reading 'title'\)/i,
    ],
    tracesSampleRate: 0.2,
  });
};

export default initializeErrorTracker;
