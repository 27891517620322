export default {
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_APPLICATION_SETTINGS: 'SET_APPLICATION_SETTINGS',
  SET_CONFIG: 'SET_CONFIG',
  SET_BOOTSTRAP_FAILED: 'SET_BOOTSTRAP_FAILED',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_TENANT: 'SET_TENANT',
  SET_REFERRER_URL: 'SET_REFERRER_URL',
  SET_MARKETING_SLOTS: 'SET_MARKETING_SLOTS',
  SET_VISUAL_VIEWPORT_HEIGHT: 'SET_VISUAL_VIEWPORT',
  SET_VISUAL_VIEWPORT_SCROLL: 'SET_VISUAL_VIEWPORT_SCROLL',
  SET_SCROLLABILITY: 'SET_SCROLLABILITY',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
};
