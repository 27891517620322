import axios from 'axios';
import config from '../../config';

const http = axios.create({
  baseURL: config.api.translationCenter,
  timeout: 10000,
});

export default {
  async loadTranslations(language, tenant) {
    const app = 'SevenCasino';
    const url = `/v2/b2b/translations/domains/${app}/languages/${language}/clients/${tenant}`;

    try {
      const { data } = await http.get(url);

      return { data };
    } catch (e) {
      return {
        isError: true,
      };
    }
  },
};
