import Game from '@/models/Game';
import { carouselsApi } from '@/api';
import gamesTypes from '@/store/modules/games/types';
import constants from '@/utility/constants';
import carouselsTypes from './types';

const appendOrderToGames = (games) => {
  games.forEach((game, i) => {
    // even items will have higher order and go in the second row https://phabricator.nsoft.ba/T126008
    // 10000 is an arbitrary high number
    const isEven = (i + 1) % 2 === 0;
    if (isEven) {
      game.setOrder(game.position + 10000);
    } else {
      game.setOrder(game.position);
    }
  });

  return games;
};

export default {
  state: () => ({
    carousels: [],
    carouselTypeSwimLane: {
      tournament: constants.VAIX.SWIM_LANE_TOURNAMENT,
      trending: constants.VAIX.SWIM_LANE_TRENDING_THIS_WEEK,
      top_n: constants.VAIX.SWIM_LANE_TOP_N,
      recommended_for_you: constants.VAIX.SWIM_LANE_RECOMMENDED,
      new_releases: constants.VAIX.SWIM_LANE_NEW_RELEASES,
      recently_played: constants.VAIX.SWIM_LANE_RECENTLY_PLAYED,
      because_you_played: constants.VAIX.SWIM_LANE_BECAUSE_YOU_PLAYED,
    },
  }),

  getters: {
    getCarousels: (state) => state.carousels,
    getSwimLaneByCarouselType: (state) => (carouselType) =>
      state.carouselTypeSwimLane[carouselType] || '',
  },

  mutations: {
    [carouselsTypes.SET_CAROUSELS](state, payload) {
      state.carousels = payload;
    },
    [carouselsTypes.ACTIVATE_CAROUSELS](state) {
      state.carousels = state.carousels.map((carousel) => {
        const activeCarousel = { ...carousel, isActive: true };

        return activeCarousel;
      });
    },
    [carouselsTypes.UPDATE_IS_CAROUSEL_ACTIVE](state, payload) {
      state.carousels = state.carousels.map((carousel) => {
        const newCarousel =
          carousel.type === payload.carouselType
            ? { ...carousel, isActive: payload.isCarouselActive }
            : carousel;
        return newCarousel;
      });
    },
  },

  actions: {
    async loadCarouselsData({ dispatch, commit, getters }) {
      const params = { ...getters.getGamesRequestParams };
      const { data: carousels, isError } = await carouselsApi.loadCarousels(params.playerUuid);
      let isBecauseYouPlayedActive;

      if (!carousels || isError) return;

      carousels.forEach((carousel) => {
        const swimLane = getters.getSwimLaneByCarouselType(carousel.type);
        const formattedGames = carousel.games?.map((game) => new Game({ ...game, swimLane }));

        if (carousel.type === constants.CAROUSELS.TOURNAMENT) {
          commit(gamesTypes.SET_TOURNAMENT_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.TOP_N) {
          commit(gamesTypes.SET_TOP_10_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.TRENDING_THIS_WEEK) {
          commit(gamesTypes.SET_TRENDING_THIS_WEEK_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.RECOMMENDED_GAMES) {
          commit(gamesTypes.SET_RECOMMENDED_GAMES, appendOrderToGames(formattedGames));
        }
        if (carousel.type === constants.CAROUSELS.NEW_RELEASES) {
          commit(gamesTypes.SET_NEW_RELEASES_GAMES, formattedGames);
        }
        if (carousel.type === constants.CAROUSELS.RECENTLY_PLAYED) {
          dispatch('loadRecentlyPlayedGames');
        }
        if (carousel.type === constants.CAROUSELS.BECAUSE_YOU_PLAYED) {
          isBecauseYouPlayedActive = true;
          dispatch('loadBecauseYouPlayedGames');
        }
      });

      commit(carouselsTypes.SET_CAROUSELS, carousels);
      commit(carouselsTypes.ACTIVATE_CAROUSELS);
      if (isBecauseYouPlayedActive) {
        commit(carouselsTypes.UPDATE_IS_CAROUSEL_ACTIVE, {
          carouselType: constants.CAROUSELS.BECAUSE_YOU_PLAYED,
          isCarouselActive: false,
        });
      }
      commit(gamesTypes.UPDATE_GAMES_FAVORITE_PROPERTY, getters.getFavoriteGamesIds);
    },
    resetCarouselsData({ commit }) {
      commit(gamesTypes.SET_TOURNAMENT_GAMES, []);
      commit(gamesTypes.SET_TOP_10_GAMES, []);
      commit(gamesTypes.SET_TRENDING_THIS_WEEK_GAMES, []);
      commit(gamesTypes.SET_RECOMMENDED_GAMES, []);
      commit(gamesTypes.SET_RECENTLY_PLAYED_GAMES, []);
      commit(gamesTypes.SET_BECAUSE_YOU_PLAYED_GAMES, []);
      commit(carouselsTypes.SET_CAROUSELS, []);
    },
  },
};
